import React, { useEffect, useState } from "react";
import { useAddress, useContract } from "@thirdweb-dev/react";
import ConnectWalletButton from "src/connect-wallet/ConnectWalletButton";
import { Button } from "src/components/ui/button";

const ImportNFT = () => {
  const address = useAddress();
  const [nfts, setNfts] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showViewNFTGuide, setShowViewNFTGuide] = useState(false);
  const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS; // Replace with the actual contract address
  const { contract } = useContract(contractAddress);

  const toggleViewNFTGuide = () => setShowViewNFTGuide(!showViewNFTGuide);

  useEffect(() => {
    const fetchNFTs = async () => {
      if (address) {
        try {
          setLoading(true);
          const userNFTs = await contract?.erc721?.getOwned(address);

          setNfts(userNFTs);
          setLoading(false);
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    };

    fetchNFTs();
  }, [address, contract]);

  const importNFTToMetaMask = async (nft: any) => {
    if (typeof window.ethereum === "undefined") {
      alert("MetaMask is not installed.");
      return;
    }
    const tokenId = nft.id;

    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC721",
          options: {
            address: contractAddress,
            tokenId: tokenId,
            symbol: "NFT",
            decimals: 0,
          },
        },
      });

      if (wasAdded) {
        alert("NFT imported to MetaMask wallet.");
      } else {
        alert("Failed to import NFT.");
      }
    } catch (error) {
      console.error("Error importing NFT to MetaMask", error);
      alert("Error importing NFT.");
    }
  };

  return (
    <div
      className="bg-[#1e2538] min-h-screen flex flex-col items-center justify-center text-white px-4"
      style={{ paddingTop: "80px" }}
    >
      <div className="bg-[#121826] rounded-lg shadow-lg p-6 w-full max-w-5xl">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Import Your NFT to MetaMask
        </h1>
        {!address ? (
          <div style={{ textAlign: "center" }}>
            <ConnectWalletButton
              claim={false}
              login={false}
              signup={false}
              registerWallet={false}
              handleWeb3Login={null}
              handleRegister={null}
              handleClaim={null}
            />
          </div>
        ) : (
          <div className="w-full max-w-4xl m-auto">
            {loading ? (
              <p className="text-center">Loading NFTs...</p>
            ) : (
              <div>
                {!nfts?.length? (
                  <p className="text-center">
                    No NFTs found in your connected wallet for this collection.
                  </p>
                ) : (
                  <ul className={`flex flex flex-wrap gap-4 justify-center`}>
                    {nfts?.map((nft: any) => (
                      <li
                        key={nft.metadata.id}
                        className="bg-[#2a334c] p-4 rounded-lg shadow-lg flex flex-col items-center"
                      >
                        <img
                          height={300}
                          width={300}
                          src={nft.metadata.image}
                          alt={nft.metadata.name}
                          className="rounded-lg mb-4"
                        />
                        <p className="text-lg font-medium mb-4 text-center">
                          {nft.metadata.name}
                        </p>
                        <button
                          onClick={() => {
                            if (window.innerWidth <= 768) {
                              toggleViewNFTGuide();
                            } else {
                              importNFTToMetaMask(nft?.metadata);
                            }
                          }}
                          className="px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg font-medium"
                        >
                          Import NFT
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        )}

        <h2 className="text-3xl font-bold mt-12 mb-6 text-center">
          Need Help? Follow These Steps
        </h2>

        <div className="flex justify-center mt-4 mb-8">
          <Button
            onClick={toggleViewNFTGuide}
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium px-6 py-2 rounded-lg"
          >
            How to Add Your NFT in MetaMask
          </Button>
        </div>

        {showViewNFTGuide && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4">
            <div className="bg-gray-800 rounded-lg shadow-lg w-full max-w-lg text-white p-6 sm:p-8 overflow-auto max-h-screen">
              <h3 className="text-xl font-bold mb-4">
                Add Your NFT to MetaMask
              </h3>
              <p className="mb-4">
                If you do not see your NFT in the MetaMask mobile application,
                follow these steps to manually import it:
              </p>
              <ol className="list-decimal list-inside space-y-2 mb-4">
                <li>
                  Copy the <span className="font-bold">Contract Address</span>{" "}
                  and <span className="font-bold">Token ID(s)</span> listed
                  below.
                </li>
                <li>
                  Open the MetaMask app, navigate to the{" "}
                  <strong>Import NFT</strong> section, and paste the details.
                </li>
                <li>
                  After importing, your NFT should be visible in your wallet
                  under the "NFTs" tab.
                </li>
                <li>Copy each token Id and import them one by one</li>
              </ol>
              {contractAddress && (
                <div className="mb-4">
                  <strong className="text-lg block">Contract Address:</strong>
                  <div className="flex items-center space-x-2">
                    <p
                      className="bg-gray-900 text-white px-4 py-2 rounded-lg flex-grow text-ellipsis overflow-hidden whitespace-nowrap lg:whitespace-normal"
                      style={{ maxWidth: "80%" }}
                    >
                      {contractAddress}
                    </p>
                    <button
                      onClick={() =>
                        navigator.clipboard.writeText(contractAddress)
                      }
                      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              )}

              <div className="mb-4">
                <strong className="text-lg block">Token IDs:</strong>
                {nfts?.length === 0 ? (
                  <p className="text-gray-400">
                    No NFTs found in your connected wallet for this collection.
                  </p>
                ) : (
                  <div>
                    {nfts?.map((nft: any) => (
                      <div
                        key={nft.metadata.id}
                        className="flex items-center space-x-2 mb-2"
                      >
                        <p
                          className="bg-gray-900 text-white px-4 py-2 rounded-lg flex-grow text-ellipsis overflow-hidden whitespace-nowrap lg:whitespace-normal"
                          style={{ maxWidth: "80%" }}
                        >
                          {nft.metadata.id}
                        </p>
                        <button
                          onClick={() =>
                            navigator.clipboard.writeText(nft.metadata.id)
                          }
                          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
                        >
                          Copy
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <p className="text-green-400 font-semibold">
                ✨ Your NFT will now appear in your MetaMask wallet!
              </p>
              <Button
                onClick={toggleViewNFTGuide}
                className="w-full mt-6 bg-red-500 hover:bg-red-600"
              >
                Close Guide
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportNFT;
